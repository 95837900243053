/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'phone-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3 2a2 2 0 012-2h6a2 2 0 012 2v12a2 2 0 01-2 2H5a2 2 0 01-2-2zm6 11a1 1 0 10-2 0 1 1 0 002 0"/>',
    },
});
